import { Center, Box, Heading, VStack, HStack, Text } from "native-base";
import { Form, Input, Button } from "antd";
import VForm from "@views/components/system/ui/antd_form";
import { useTranslation } from "react-i18next";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { login_user_query } from "@services/redux/slices/graphql/graphql_auth";
import { useEffect } from "react";
import { storeItem } from "@helpers/functions";

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    user_id,
    session_id,
    hospital_id,
    page_accesses,
    page_component_accesses,
    error: login_error,
    loading: login_loading,
    roles,
  } = useDynamicSelector("login_user");

  useEffect(() => {
    if (session_id) {
      storeItem("session_id", session_id);
      storeItem("user_id", user_id);
      storeItem("hospital_id", hospital_id);
      storeItem("page_accesses", page_accesses);
      storeItem("page_component_accesses", page_component_accesses);
      storeItem("roles", roles);
    }
  }, [session_id]);

  const handleSubmit = (values) => {
    let variables = {
      json: values,
    };
    let keys = [{ key: "login_user", loading: true }];
    dispatch(dynamicRequest(keys, login_user_query, variables));
  };
  return (
    <Box
      style={{
        flex: 1,
        backgroundImage:
          "url(https://blr1.vultrobjects.com/docy-assets/login_bg.png)",
      }}
    >
      <Center height={"85%"}>
        <Box safeArea p="6" py="8" w="90%" maxW="360" backgroundColor="white">
          <Box
            width={"100%"}
            height={"40px"}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            style={{
              backgroundImage:
                "url(https://blr1.vultrobjects.com/docy-assets/login_top.png)",
            }}
          />
          <VStack space={3} mt="5">
            <Form
              form={form}
              name={"login_credentials"}
              layout={"vertical"}
              onFinish={handleSubmit}
              className="user_form ant-select-selector ant-select-selection-search"
            >
              <VForm.TextBox
                label={t("Username")}
                field={"username"}
                rules={[
                  {
                    required: true,
                    message: `${t("form:username_msg")}`,
                  },
                ]}
              />

              <VForm.Password
                label={t("Password")}
                field={"password"}
                rules={[
                  {
                    required: true,
                    message: `${t("form:password_msg")}`,
                  },
                ]}
              />
              <Form.Item style={{ marginTop: "40px" }}>
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  htmlType="submit"
                  loading={login_loading}
                >
                  {t("Login")}
                </Button>
              </Form.Item>
            </Form>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

export default LoginForm;
