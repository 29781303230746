import React from "react";
import { Box, Center, HStack, Menu, Pressable, Text } from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { clearLocalStorage, navigate, retrieveItem } from "@helpers/functions";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import HeaderBreadcrumb from "./header_breadcrumb";

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const roles = retrieveItem("roles");
  const handleLogout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("login_user"));
    navigate(ROUTES.LOGIN);
  };
  const handleChangePassword = () => {};

  const handleAccount = () => {};
  return (
    <Box h={"80px"} w="100%" bg="#ffffff">
      <HStack
        h="100%"
        w="100%"
        bg="#ffffff"
        space="2"
        justifyContent={"space-between"}
      >
        <Center ml="20px">
          <HeaderBreadcrumb />
        </Center>
        <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <HStack>
                        <Text bold fontSize="md" fontWeight="">
                          Welcome {roles?.[0]}
                        </Text>
                        <Box right="10px" ml="6">
                          <Avatar
                            round
                            size="25"
                            src={"https://via.placeholder.com/150"}
                          />
                        </Box>
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                {/* <Menu.Item onPress={handleAccount}>Account</Menu.Item>
                <Menu.Item onPress={handleChangePassword}>
                  Change Password
                </Menu.Item> */}

                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default HeaderBar;
