import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import { Center, Box, Text, HStack } from "native-base";
import HospitalProducts from "@views/pages/hospital_products";
import VForm from "@views/components/system/ui/antd_form";
import { useTranslation } from "react-i18next";

const MedicineDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  //   const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {};

  return (
    <Form
      form={form}
      name={"medicine_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: `${t("form:name_msg")}`,
          },
        ]}
      />

      <VForm.TextArea
        label={t("stock_count")}
        field={"stock_count"}
        rules={[
          {
            required: true,
            message: `${t("form:stock_count_msg")}`,
          },
        ]}
      />

      <VForm.Number
        label={t("price")}
        field={"price"}
        rules={[
          {
            required: true,
            message: `${t("form:price_msg")}`,
          },
        ]}
      />

      <HStack variant="submit_hstack">
        <VForm.Button
          //   isLoading={props.label === "Add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default MedicineDetails;
