import { gql } from "@apollo/client";

export const login_user_query = gql`
  query loginUser($json: login_input) {
    login_user: loginUser(json: $json) {
      user_id
      session_id
      hospital_id
      roles
      page_accesses
      page_component_accesses
      error {
        status_code
        message
      }
    }
  }
`;
