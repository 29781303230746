import React, { useEffect, useState } from "react";
import { Card, Switch, Form, Divider } from "antd";
import {
  HStack,
  VStack,
  Text,
  Box,
  Button,
  Pressable,
  Center,
  Skeleton,
} from "native-base";
import { FaHospitalUser, FaBed } from "react-icons/fa";
import { BsCircleFill } from "react-icons/bs";
import Scrollbars from "@views/components/system/scroll_bars";
// import Scrollbars from "react-custom-scrollbars-2";

import {
  AiOutlinePlusCircle,
  AiOutlinePoundCircle,
  AiOutlineProfile,
} from "react-icons/ai";
import { debounce } from "lodash";
import { dynamicClear, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
const AdmissionAndVisit = (props) => {
  const {
    handleTitleClick,
    handleOpen,
    handleCreateWithTime,
    currentIndex,
    setCurrentIndex,
    hospital_patient_loading,
  } = props;
  const dates = props.dates;
  const dispatch = useDispatch();

  const { status: create_hospital_patient_note_status } = useDynamicSelector(
    "createHospitalPatientNote"
  );
  const [activeTitle, setActiveTitle] = useState(null);

  // const handleVisibleTitle = (i) => {
  //   const centeredComponent = document.getElementById(`active-${i}`);
  //   if (centeredComponent) {
  //     centeredComponent.scrollIntoView({ behavior: "smooth", block: "center" });
  //   }
  // };
  // const debouncedHandleTitleClick = debounce(handleVisibleTitle, 200);

  useEffect(() => {
    setActiveTitle("");
    // debouncedHandleTitleClick(currentIndex);
  }, [currentIndex, activeTitle]);

  useEffect(() => {
    if (create_hospital_patient_note_status === "success") {
      setCurrentIndex("");
      dispatch(dynamicClear("createHospitalPatientNote"));
    }
  }, [create_hospital_patient_note_status]);

  const dummyArray = new Array(15).fill(0);

  return (
    <Card
      // title="Visits"
      // bordered={false}

      style={{
        // width: 280,
        textAlign: "center",
        borderRadius: "15px",
      }}
    >
      <VStack marginRight={"3"}>
        {/* <Scrollbars style={{ flex: 1 }}> */}
        <HStack justifyContent={"center"} alignItems={"center"}>
          {/* <Button
            style={{ backgroundColor: "#a07aeb" }}
            onPress={() => {
              handleOpen("in_person");
            }}
            width={"100%"}
          > */}
          <Box width={"50%"} justifyContent={"center"} alignItems={"center"}>
            <Text color={"#a07aeb"} bold>
              {"VISITS"}
            </Text>
          </Box>

          <Pressable
            width={"25%"}
            alignItems={"flex-end"}
            // position={"absolute"}
            onPress={() => {
              handleOpen("in_person");
            }}
          >
            <AiOutlinePlusCircle color="#a07aeb" size={"30px"} />
          </Pressable>

          <Pressable
            width={"25%"}
            alignItems={"flex-end"}
            // position={"absolute"}
            onPress={() => {
              handleCreateWithTime("in_person");
            }}
          >
            <AiOutlineProfile color="#a07aeb" size={"30px"} />
          </Pressable>

          {/* <HStack space={"2"}>
              <FaHospitalUser color="white" size={"20px"} />
              <Text color={"white"}>New Visit</Text>
            </HStack> */}
          {/* </Button> */}
          {/* <Button style={{ backgroundColor: "#5cdba2" }} width={"50%"}>
            <HStack space={"2"}>
              <FaBed color="white" size={"20px"} />
              <Text color={"white"}>Admission</Text>
            </HStack>
          </Button> */}
        </HStack>
        <Divider
          style={{
            color: "#111",
            borderBlockColor: "#111",
          }}
        />
        <Scrollbars
          style={{
            minHeight: "350px",
          }}
        >
          {hospital_patient_loading ? (
            <VStack textAlign={"left"} space={"2"}>
              {dummyArray?.map((x, i) => {
                return (
                  <HStack space={3} alignItems={"center"}>
                    <Box
                      marginLeft={2.5}
                      width={"20px"}
                      height={"20px"}
                      textAlign={"center"}
                      style={{
                        backgroundColor: "#a07aeb",
                        borderRadius: "100%",
                        alignContent: "center",
                      }}
                    ></Box>
                    <Skeleton
                      style={{
                        height: 15,
                        borderRadius: "5%",
                      }}
                      lines={1}
                      key={i}
                      width={"100%"}
                    />
                  </HStack>
                );
              })}
            </VStack>
          ) : (
            <VStack alignItems={"center"} textAlign={"left"} space={"2"}>
              {dates?.map((date, i) => {
                let bgc = "#5cdba2";
                if (date.type === "in_person") {
                  bgc = "#a07aeb";
                }
                return (
                  <div
                    key={i}
                    id={`active-${i}`}
                    style={{
                      height: 20,
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        handleTitleClick(i);
                        setActiveTitle(i);
                        setCurrentIndex("");
                        // debouncedHandleTitleClick("");
                      }}
                    >
                      <HStack space={"3"}>
                        {activeTitle === i || currentIndex === i ? (
                          <Box
                            width={"20px"}
                            height={"20px"}
                            textAlign={"center"}
                            style={{
                              backgroundColor: "#a07aeb",
                              borderRadius: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Center m="auto">
                              <BsCircleFill size={"10px"} color={"#fff"} />
                            </Center>
                          </Box>
                        ) : (
                          <Box
                            width={"20px"}
                            height={"20px"}
                            textAlign={"center"}
                            style={{
                              backgroundColor: "#a07aeb",
                              borderRadius: "100%",
                              alignContent: "center",
                            }}
                          >
                            {/* <BsCircleFill color={bgc} /> */}
                          </Box>
                        )}
                        <Box>
                          <a>
                            <Text>
                              {new Date(date.datetime).toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                            </Text>
                          </a>
                        </Box>
                      </HStack>
                    </Pressable>
                  </div>
                );
              })}
            </VStack>
          )}
        </Scrollbars>
      </VStack>
    </Card>
  );
};

export default AdmissionAndVisit;
