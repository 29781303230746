import React, { useEffect } from "react";

import LoginForm from "@views/components/user/login/login_form";
import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/functions";
import { ROUTES } from "@views/routes/my_routes";

const Login = () => {
  const is_logged_in = useCheckLogin();
  useEffect(() => {
    if (is_logged_in === true) {
      navigate(ROUTES.PATIENT_LIST);
    }
  }, [is_logged_in]);

  return <LoginForm />;
};
export default Login;
