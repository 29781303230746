import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Card } from "antd";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Divider,
  Button,
  Image,
  View,
  Skeleton,
} from "native-base";
import { MdLocationOn } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { getProfileUrl } from "@helpers/functions";
import { MdSettingsPhone } from "react-icons/md";

const { Meta } = Card;
const PatientDetailsComponent = (props) => {
  const birthDate = new Date(props?.age);
  const ageInMilliseconds = Date.now() - birthDate.getTime();
  const ageInYears = ageInMilliseconds / 1000 / 60 / 60 / 24 / 365.25;
  const roundedAge = Math.round(ageInYears);
  return (
    <Box>
      <Box alignItems={"center"}>
        <View
          style={{
            borderColor: "#8FBC8F",
            borderWidth: 4,
          }}
        >
          {props?.hospital_patient_loading ? (
            <Skeleton
              borderColor="coolGray.200"
              endColor="warmGray.50"
              height={200}
              width={200}
            ></Skeleton>
          ) : (
            // <Image
            //   source={{
            //     uri: "https://cdn.standardmatrimony.com/male-avatar.png",
            //   }}
            //   alt="My Image"
            //   resizeMode="cover"
            //   width={200}
            //   height={200}
            // />
            <Image
              source={{ uri: getProfileUrl(props?.url, props?.gender) }}
              alt="My Image"
              resizeMode="cover"
              width={200}
              height={200}
            />
          )}
        </View>
        {/* <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        > */}
        {/* <Image width={200} src={getProfileUrl(props?.url, props?.gender)} /> */}
        {/* </Image.PreviewGroup> */}
      </Box>
      <Box>
        <Center marginTop={5}>
          {props?.hospital_patient_loading ? (
            <Skeleton
              style={{
                height: 18,
                borderRadius: "5%",
              }}
              lines={1}
              width={"50%"}
            />
          ) : (
            <Meta title={props.patient_name} />
          )}
        </Center>
        <br></br>

        <HStack justifyContent={"center"} width={"100%"}>
          <Box width={"30%"}>
            <VStack>
              <Text textAlign={"center"} fontSize={10}>
                AGE
              </Text>
              {props?.hospital_patient_loading ? (
                <Skeleton
                  style={{
                    height: 18,
                    borderRadius: "5%",
                  }}
                  lines={1}
                  width={"100%"}
                />
              ) : (
                <Text bold textAlign={"center"} fontSize={14}>
                  {roundedAge}
                </Text>
              )}
            </VStack>
          </Box>
          <Divider
            style={{ backgroundColor: "#000" }}
            mx={3}
            height={39}
            orientation="vertical"
          />
          <Box width={"40%"}>
            <VStack>
              <Text textAlign={"center"} fontSize={10}>
                ID
              </Text>
              {props?.hospital_patient_loading ? (
                <Skeleton
                  style={{
                    height: 18,
                    borderRadius: "5%",
                  }}
                  lines={1}
                  width={"100%"}
                />
              ) : (
                <Text bold textAlign={"center"} fontSize={14}>
                  {props.id}
                </Text>
              )}
            </VStack>
          </Box>
          <Divider
            style={{ backgroundColor: "#000" }}
            mx={3}
            height={39}
            orientation="vertical"
          />
          <Box width={"30%"}>
            <VStack>
              <Text textAlign={"center"} fontSize={10}>
                GENDER
              </Text>

              {props?.hospital_patient_loading ? (
                <Skeleton
                  style={{
                    height: 18,
                    borderRadius: "5%",
                  }}
                  lines={1}
                  width={"100%"}
                />
              ) : (
                <Text bold textAlign={"center"} fontSize={14}>
                  {props.gender === "m"
                    ? "Male"
                    : props.gender === "f"
                    ? "Female"
                    : "Others"}
                </Text>
              )}
            </VStack>
          </Box>
        </HStack>
        <Divider style={{ backgroundColor: "#000" }} my={3} />
        <Box marginLeft={"2"}>
          <HStack marginLeft={"1"} space={"6"}>
            <Box mt={".5"}>
              <MdSettingsPhone size={"20px"} />
            </Box>
            {props?.hospital_patient_loading ? (
              <Skeleton
                style={{
                  height: 18,
                  borderRadius: "5%",
                }}
                lines={1}
                width={"80%"}
              />
            ) : (
              <Text>{props.mobile}</Text>
            )}
          </HStack>
          {props.address && (
            <HStack mt={2} marginLeft={"1"} space={"6"}>
              <Box mt={".5"}>
                <MdLocationOn size={"20px"} />
              </Box>
              {props?.hospital_patient_loading ? (
                <Skeleton
                  style={{
                    height: 18,
                    borderRadius: "5%",
                  }}
                  lines={1}
                  width={"80%"}
                />
              ) : (
                <Text>{props.address}</Text>
              )}
            </HStack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PatientDetailsComponent;
