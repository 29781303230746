import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import { Center, Box, Text, HStack } from "native-base";
import HospitalProducts from "@views/pages/hospital_products";
import VForm from "@views/components/system/ui/antd_form";
import { useTranslation } from "react-i18next";
import {
  create_hospital_mutation,
  dynamicRequest,
  update_hospital_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const HospitalDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_hospital");
  const {
    status: updateStatus,
    error: updateError,
    loading: updateLoading,
  } = useDynamicSelector("update_hospital");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {
    values["mobile"] = values?.mobile.toString();
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "update_hospital", loading: true }];
      dispatch(dynamicRequest(keys, update_hospital_mutation, variables, "M"));
    } else {
      let variables = {
        json: {
          data: values,
        },
      };
      let keys = [{ key: "create_hospital", loading: true }];
      dispatch(dynamicRequest(keys, create_hospital_mutation, variables, "M"));
    }
  };

  return (
    <Form
      form={form}
      name={"hospital_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: `${t("form:name_msg")}`,
          },
        ]}
      />

      <VForm.TextArea
        label={t("Address")}
        field={"address"}
        rules={[
          {
            required: true,
            message: `${t("form:address_msg")}`,
          },
        ]}
      />
      <VForm.TextBox
        label={t("City")}
        field={"city"}
        rules={[
          {
            required: true,
            message: `${t("form:city_msg")}`,
          },
        ]}
      />
      <VForm.TextBox
        label={t("State")}
        field={"state"}
        rules={[
          {
            required: true,
            message: `${t("form:state_msg")}`,
          },
        ]}
      />
      <VForm.TextBox
        label={t("Country")}
        field={"country"}
        rules={[
          {
            required: true,
            message: `${t("form:country_msg")}`,
          },
        ]}
      />
      <VForm.Number
        label={t("mobile_number")}
        field={"mobile"}
        rules={[
          {
            required: true,
            message: `${t("form:mobile_msg")}`,
          },
        ]}
      />

      <HStack variant="submit_hstack">
        <VForm.Button
          isLoading={props.label === "add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default HospitalDetails;
