// import UserLayout from "@views/layouts/user_layout";

// import Login from "@views/pages/login";
// import Dashboard from "@views/pages/dashboard";
// import List from "@views/pages/list";

// export const ROUTES = {
//   LOGIN: "/login",
//   DASHBOARD: "/dashboard",
//   LIST: "/list",
// };

// const myRoutes = [
//   {
//     path: ROUTES.LOGIN,
//     exact: true,
//     page_key: "login",
//     component: Login,
//     authenticate: false,
//   },
//   {
//     key: "user",
//     component: UserLayout,
//     authenticate: false,
//     children: [
//       {
//         path: ROUTES.DASHBOARD,
//         exact: true,
//         key: "dashboard",
//         component: Dashboard,
//         authenticate: true,
//       },
//       {
//         path: ROUTES.LIST,
//         exact: true,
//         key: "list",
//         component: List,
//         authenticate: true,
//       },
//     ],
//   },
// ];

// export default myRoutes;

import UserLayout from "@views/layouts/user_layout";

import Login from "@views/pages/login";
import Dashboard from "@views/pages/dashboard";
import List from "@views/pages/list";
import Patients from "@views/pages/patients";
import HospitalProducts from "@views/pages/hospital_products";
import HospitalEditPage from "@views/pages/hospital_details";
import MedicineList from "@views/components/user/medicines/medicine_list";
import HospitalList from "@views/components/user/hospital/hospital_list";
import PatientPage from "@views/components/user/patients";
import AccessRightsPage from "@views/pages/access_rights";
import Redirector from "@views/pages/redirector";

export const ROUTES = {
  ACCESS_RIGHTS: "/access-rights",
  LOGIN: "/login",
  REDIRECTOR: "/",
  DASHBOARD: "/dashboard",
  LIST: "/list",
  HOSPITAL: "/hospital",
  PATIENT_LIST: "/patients",
  PATIENT: "/patient/",
  HOSPITAL_PRODUCTS: "/hospital/products",
  HOSPITAL_DETAILS: "/hospital/details",
  MEDICINES: "/medicine",
};

const myRoutes = [
  {
    path: ROUTES.ACCESS_RIGHTS,
    exact: true,
    page_key: "access_rights",
    component: AccessRightsPage,
    authenticate: false,
  },
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "login",
    component: Login,
    authenticate: false,
  },
  {
    key: "user",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        key: "dashboard",
        component: Dashboard,
        // authenticate: true,
      },
      {
        path: ROUTES.LIST,
        exact: true,
        key: "list",
        component: List,
        // authenticate: true,
      },
      {
        path: ROUTES.HOSPITAL,
        exact: true,
        key: "hospital",
        component: HospitalList,
        // authenticate: true,
      },
      {
        path: ROUTES.PATIENT_LIST,
        exact: true,
        key: "patient",
        component: Patients,
        // authenticate: true,
      },
      {
        path: ROUTES.HOSPITAL_PRODUCTS,
        exact: true,
        key: "hospital_products",
        component: HospitalProducts,
        // authenticate: true,
      },
      {
        path: `${ROUTES.HOSPITAL_DETAILS}`,
        exact: true,
        key: "hospital_details",
        component: HospitalEditPage,
        // authenticate: true,
      },
      {
        path: `${ROUTES.PATIENT}:id`,
        key: "patient_details",
        component: PatientPage,
      },
      {
        path: ROUTES.MEDICINES,
        exact: true,
        key: "medicine",
        component: MedicineList,
      },
    ],
  },
];

export default myRoutes;
