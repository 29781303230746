import React, { useEffect, useState } from "react";
import {
  Center,
  Box,
  Text,
  VStack,
  HStack,
  Button,
  Tooltip,
  Pressable,
} from "native-base";
import { Link } from "react-router-dom";
import {
  Space,
  Table,
  Input,
  Typography,
  Popconfirm,
  Breadcrumb,
  Pagination,
} from "antd";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineUser,
  AiFillCaretUp,
  AiFillCaretDown,
} from "react-icons/ai";
import PatientDetails from "./patient_details";
import { useTranslation } from "react-i18next";
import AntdModal from "../../system/ui/antd_modal/antd_modal";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  delete_patient,
  get_all_patients_query,
} from "@services/redux/slices/graphql/graphql_patients";
import { toast } from "react-hot-toast";
import Scrollbars from "react-custom-scrollbars-2";
import moment from "moment";

const PatientList = () => {
  const { Search } = Input;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    items,
    pagination,
    loading: all_patients_loading,
  } = useDynamicSelector("getHospitalPatients");
  const [modal, setModal] = useState(false);
  const [actionItem, setActionItem] = useState("");
  const [sort_type, setSortType] = useState("asc");
  const [sort_key_value, setSortKeyValue] = useState("name");
  const [actionType, setActionType] = useState({});
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);

  const {
    status: create_hospital_patient_status,
    error: create_patient_error,
    loading: createLoading,
  } = useDynamicSelector("createHospitalPatient");
  const {
    status: update_hospital_patient_status,
    error: update_patient_error,
    loading: updateLoading,
  } = useDynamicSelector("updateHospitalPatient");

  const {
    status: delete_hospital_patient_status,
    error: delete_patient_error,
    loading: deleteLoading,
  } = useDynamicSelector("deleteHospitalPatient");
  const handleModalClose = () => {
    setModal(false);
  };
  const handleAdd = (value) => {
    setActionType("add");
    setActionItem({});
    setModal(true);
  };

  const handleEditPatient = (value) => {
    setActionItem(value);
    setActionType("edit");
    setModal(true);
  };

  const handleDeletePatient = (value) => {
    let variables = {
      json: { id: value },
    };

    let keys = [{ key: "deleteHospitalPatient", loading: true }];
    dispatch(dynamicRequest(keys, delete_patient, variables));
  };
  const searchPatient = (value) => {
    let variables = {
      json: { search_string: value },
    };

    let keys = [{ key: "getHospitalPatients", loading: true }];
    dispatch(dynamicRequest(keys, get_all_patients_query, variables));
  };

  const sortPatientsList = (sort_key, sort_order) => {
    let variables = {
      json: {
        page_number: current,
        page_limit: current_limit,
        sort_key: sort_key,
        sort_order: sort_order,
      },
    };

    let keys = [{ key: "getHospitalPatients", loading: true }];
    dispatch(dynamicRequest(keys, get_all_patients_query, variables));
  };

  const handleChange = (value) => {
    if (value?.length > 2) {
      searchPatient(value);
    } else if (value.length === 0) {
      patientsList();
    }
  };
  const handleSearch = (value) => {
    if (value?.length > 2) {
      searchPatient(value);
    }
  };
  const columns = [
    {
      title: (
        <span>
          <HStack>
            <Text mx={5}>{"ID"}</Text>
            <VStack>
              <Pressable
                onPress={() => {
                  setSortKeyValue("ref_id");
                  setSortType("asc");
                  sortPatientsList("ref_id", "asc");
                }}
              >
                <AiFillCaretUp />
              </Pressable>
              <Pressable
                onPress={() => {
                  setSortKeyValue("ref_id");
                  setSortType("desc");
                  sortPatientsList("ref_id", "desc");
                }}
              >
                <AiFillCaretDown />
              </Pressable>
            </VStack>
          </HStack>
        </span>
      ),
      dataIndex: "ref_id",
      key: "ref_id",
      render: (text, record) => (
        <Link to={`${ROUTES.PATIENT}${record.id}`}>{text}</Link>
      ),
    },
    {
      title: (
        <span>
          <HStack>
            <Text mx={5}>{"Name"}</Text>
            <VStack>
              <Pressable
                onPress={() => {
                  setSortKeyValue("name");
                  setSortType("asc");
                  sortPatientsList("name", "asc");
                }}
              >
                <AiFillCaretUp />
              </Pressable>
              <Pressable
                onPress={() => {
                  setSortKeyValue("name");
                  setSortType("desc");
                  sortPatientsList("name", "desc");
                }}
              >
                <AiFillCaretDown />
              </Pressable>
            </VStack>
          </HStack>
        </span>
      ),
      // title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`${ROUTES.PATIENT}${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Age",
      dataIndex: "dob",
      key: "dob",
      render: (record) => {
        // const birthDate = new Date(record);
        // const ageInMilliseconds = Date.now() - birthDate.getTime();
        // const ageInYears = ageInMilliseconds / 1000 / 60 / 60 / 24 / 365.25;
        // const roundedAge = Math.round(ageInYears);
        let roundedAge = moment().diff(record, "years");

        return <Text>{`${roundedAge}`}</Text>;
      },
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (record) => (
        <Text>
          {record === "m" ? "Male" : record === "f" ? "Female" : "Others"}
        </Text>
      ),
    },
    // {
    //   title: "Has WhatsApp",
    //   dataIndex: "has_whatsapp",
    //   key: "has_whatsapp",
    //   render: (record) => (
    //     <Text>
    //       {console.log("ff", record)}
    //       {record === true ? "Yes" : "No"}
    //     </Text>
    //   ),
    // },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link onClick={() => handleEditPatient(record)}>
              <Space>
                <Tooltip title={t("edit")} placement="bottom">
                  <AiOutlineEdit />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              title={t("error:delete_confirmation")}
              onConfirm={() => handleDeletePatient(record?.id)}
              okButtonProps={{
                loading: deleteLoading,
              }}
            >
              <Space>
                <Tooltip title={t("delete")} placement="bottom">
                  <AiOutlineDelete />
                </Tooltip>
              </Space>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      id: "1",
      name: "Govt. hospital",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      id: "2",
      name: "Apollo",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      id: "3",
      name: "Agarwal",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];

  const patientsList = () => {
    let variables = {
      json: {
        page_number: current,
        page_limit: current_limit,
        sort_key: sort_key_value,
        sort_order: sort_type,
      },
    };

    let keys = [{ key: "getHospitalPatients", loading: true }];
    dispatch(dynamicRequest(keys, get_all_patients_query, variables));
  };
  useEffect(() => {
    patientsList();
  }, []);

  useEffect(() => {
    if (create_hospital_patient_status === "success") {
      toast.success("Patient Created");
      dispatch(dynamicClear("createHospitalPatient"));
      patientsList();

      handleModalClose();
    } else if (create_patient_error) {
      toast.error(create_patient_error?.message);
      dispatch(dynamicClear("createHospitalPatient"));
    } else if (update_hospital_patient_status === "success") {
      toast.success("Patient Details Updated");
      dispatch(dynamicClear("updateHospitalPatient"));
      patientsList();

      handleModalClose();
    } else if (update_patient_error) {
      toast.error(update_patient_error?.message);
      dispatch(dynamicClear("updateHospitalPatient"));
    } else if (delete_hospital_patient_status === "success") {
      toast.success("Patient Deleted");
      dispatch(dynamicClear("deleteHospitalPatient"));
      patientsList();
    } else if (delete_patient_error) {
      toast.error(delete_patient_error?.message);
      dispatch(dynamicClear("deleteHospitalPatient"));
    }
  }, [
    create_hospital_patient_status,
    update_hospital_patient_status,
    delete_hospital_patient_status,
  ]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getHospitalPatients", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_patients_query, {
        json: {
          page_number: page_number,
          page_limit: page_limit,
          sort_key: sort_key_value,
          sort_order: sort_type,
        },
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  return (
    <Scrollbars>
      <VStack my={2}>
        <Box paddingX={5} paddingTop={5}>
          <HStack justifyContent="flex-end">
            <Box width={"20%"} marginLeft={"2"}>
              <Search
                placeholder="search patients"
                onSearch={handleSearch}
                enterButton
                allowClear
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
            </Box>
            <Box key={`header-action`}>
              <Button height={"33px"} type="primary" onPress={handleAdd}>
                {t("add_patient")}
              </Button>
            </Box>
          </HStack>
        </Box>

        <Box p={5}>
          <Table
            columns={columns}
            dataSource={items}
            pagination={false}
            loading={all_patients_loading}
          />
        </Box>
        <Box mx={5}>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </Box>

        {/* <Pagination
			  total={pagination?.total_count}
			  showSizeChanger={true}
			  current={current}
			  hideOnSinglePage={false}
			  onChange={fetchData}
			  pageSizeOptions={[1, 5, 10, 20, 50]}
			  showTotal={(total, range) =>
				`${range[0]}-${range[1]} of ${total} topics`
			  }
			/> */}
      </VStack>

      <AntdModal
        isOpen={modal}
        onClose={handleModalClose}
        width={550}
        header={actionType === "add" ? t("add_patient") : t("update_patient")}
        component={
          <PatientDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </Scrollbars>
  );
};

export default PatientList;
