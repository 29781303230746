import { gql } from "@apollo/client";

export const create_hospital_patient_visit = gql`
  mutation createHospitalPatientVisit(
    $json: create_hospital_patient_visit_input
  ) {
    createHospitalPatientVisit(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_hospital_patient_note = gql`
  mutation createHospitalPatientNote(
    $json: create_hospital_patient_note_input
  ) {
    createHospitalPatientNote(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
