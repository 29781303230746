import { gql } from "@apollo/client";

export const create_hospital_patient = gql`
  mutation createHospitalPatient($json: create_hospital_patient_input) {
    createHospitalPatient(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_hospital_patient = gql`
  mutation updateHospitalPatient($json: update_hospital_patient_input) {
    updateHospitalPatient(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_patient = gql`
  mutation deleteHospitalPatient($json: delete_hospital_patient_input) {
    deleteHospitalPatient(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_patients_query = gql`
  query getHospitalPatients($json: get_all_hospital_patient_input) {
    getHospitalPatients(json: $json) {
      items {
        id
        ref_id
        name
        mobile
        address
        gender
        has_whatsapp
        referred_by_id
        dob
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_patients_search_query = gql`
  query getHospitalPatients($json: get_all_hospital_patient_input) {
    get_hospital_patients_search: getHospitalPatients(json: $json) {
      items {
        id
        ref_id
        name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_patient_query = gql`
  query getHospitalPatient($json: get_hospital_patient_input) {
    getHospitalPatient(json: $json) {
      id
      name
      dob
      gender
      ref_id
      mobile
      address
      my_visits {
        id
        datetime
        type

        documents {
          hospital_patient_visit_id
          document_type
          file_type
          hospital_file {
            id
            file_url
          }
        }
        document_pending_requests {
          remarks
        }
        notes {
          content
          datetime
        }
      }
    }
  }
`;

export const create_file_upload_request = gql`
  mutation createFileUploadRequest($json: file_upload_request_input) {
    createFileUploadRequest(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_visit_card = gql`
  mutation deleteHospitalPatientVisit(
    $json: delete_hospital_patient_visit_input
  ) {
    deleteHospitalPatientVisit(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const fileQuery = gql`
  mutation {
    createHospitalPatientVisitDocumentUploadUrl {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;

export const completeUploadRequest = gql`
  mutation completeHospitalPatientVisitDocumentUpload(
    $photo_url: String
    $document_type: String
    $hospital_patient_id: String
    $hospital_patient_visit_id: String
  ) {
    completeHospitalPatientVisitDocumentUpload(
      photo_url: $photo_url
      document_type: $document_type
      hospital_patient_id: $hospital_patient_id
      hospital_patient_visit_id: $hospital_patient_visit_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
