import { ROUTES } from "@views/routes/my_routes";
import { Breadcrumb } from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const HeaderBreadcrumb = (props) => {
  const { pathname } = useLocation();
  return (
    <Breadcrumb>
      {pathname != ROUTES.DASHBOARD && (
        <Breadcrumb.Item>
          <Link to={ROUTES.DASHBOARD}>
            <HomeOutlined style={{ fontSize: "16px" }} />
          </Link>
        </Breadcrumb.Item>
      )}
      {pathname === ROUTES.PATIENT_LIST && (
        <Breadcrumb.Item>PATIENTS</Breadcrumb.Item>
      )}
      {pathname.indexOf(ROUTES.PATIENT) > -1 && (
        <>
          <Breadcrumb.Item>
            <Link to={ROUTES.PATIENT_LIST}>PATIENTS</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>PATIENT</Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
};

export default HeaderBreadcrumb;
