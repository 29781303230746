import React, { useEffect, useState } from "react";
import { Center, Box, Text, VStack, HStack, Button } from "native-base";
import {
  Space,
  Table,
  Input,
  Typography,
  Tooltip,
  Popconfirm,
  Breadcrumb,
} from "antd";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
// import { dynamicRequest, useDynamicSelector } from "@services/redux";
// import {  get_hospital } from "@services/redux";
import { useDispatch } from "react-redux";
import MedicineDetails from "./medicine_details";
import { useTranslation } from "react-i18next";
import AntdModal from "../../system/ui/antd_modal/antd_modal";
// import { useDynamicSelector } from "@services/redux/selector";
import { FaHospitalUser } from "react-icons/fa";
import Scrollbars from "@views/components/system/scroll_bars";

const MedicineList = () => {
  const { Search } = Input;
  const hospital_items = [];
  const { t } = useTranslation();
  const hospital_loading = false;
  const [modal, setModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionItem, setActionItem] = useState({});
  const [current, setCurrent] = useState(1);
  const history = useHistory();
  const handleSearch = (value) => {};
  const handleModalClose = () => {
    setModal(false);
  };
  const handleEditMedicines = (value) => {
    setActionType("edit");
    setActionItem(value);
    setModal(true);
  };

  const handleAdd = () => {
    setModal(true);
    setActionType("add");
    setActionItem({});
  };

  const handleDeleteMedicine = (value) => {};
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Stock Count",
      dataIndex: "stock_count",
      key: "stock-count",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    // {
    // 	title: "Tags",
    // 	key: "tags",
    // 	dataIndex: "tags",
    // 	render: (_, { tags }) => (
    // 		<>
    // 			{tags.map((tag) => {
    // 				let color = tag.length > 5 ? "geekblue" : "green";
    // 				if (tag === "loser") {
    // 					color = "volcano";
    // 				}
    // 				return (
    // 					<Tag color={color} key={tag}>
    // 						{tag.toUpperCase()}
    // 					</Tag>
    // 				);
    // 			})}
    // 		</>
    // 	),
    // },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link onClick={() => handleEditMedicines(record)}>
              <Space>
                <Tooltip title={t("edit")} placement="bottom">
                  <AiOutlineEdit />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              title={t("error:delete_confirmation")}
              onConfirm={() => handleDeleteMedicine(record)}
            >
              <Space>
                <Tooltip title={t("delete")} placement="bottom">
                  <AiOutlineDelete />
                </Tooltip>
              </Space>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      name: "Dolo 650",
      stock_count: 5,
      price: "650",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Dolo 350",
      stock_count: 2,
      price: "350",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Dolo 720",
      stock_count: 6,
      price: "720",
      tags: ["cool", "teacher"],
    },
  ];
  return (
    <Scrollbars style={{ flex: 1 }}>
      <Box variant="text_book_board_heading_box">
        {/* <Text variant="text_book_board_heading_text">
		 {t("hospitals")}
	  </Text> */}
        <Box marginLeft={"42px"} marginTop={"40px"}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={ROUTES.DASHBOARD}>HOME</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>MEDICINES</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>
      <Box variant="text_book_board_top_box">
        <VStack variant="text_book_board_top_vstack">
          <HStack variant="add_text_book_board_button_hstack">
            <Box width={"20%"} marginLeft={"2"}>
              <Search
                className="hospital-search"
                placeholder="search Medicines"
                onSearch={handleSearch}
                enterButton
                allowClear
              />
            </Box>
            <Box key={`header-action`}>
              <Button height={"33px"} type="primary" onPress={handleAdd}>
                {t("add_hospital")}
              </Button>
            </Box>
          </HStack>
          <Box p={5}>
            <Table columns={columns} dataSource={data} pagination={false} />
            {/* <Pagination
            className="hospital-pagination"
		  total={pagination?.total_count}
		  showSizeChanger={true}
		  current={current}
		  hideOnSinglePage={false}
		  onChange={fetchData}
		  pageSizeOptions={[1, 5, 10, 20, 50]}
		  showTotal={(total, range) =>
			`${range[0]}-${range[1]} of ${total} topics`
		  }
		/> */}
          </Box>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modal}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("add_medicine") : t("update_medicine")}
        component={
          <MedicineDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </Scrollbars>
  );
};

export default MedicineList;
