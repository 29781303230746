import { gql } from "@apollo/client";

export const get_all_hospitals_query = gql`
  query getHospitals($json: get_all_hospital_input) {
    get_all_hospitals: getHospitals(json: $json) {
      items {
        id
        name
        mobile
        address
        city
        state
        country
        gst_number
        has_pharmacy
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_hospital_query = gql`
  query getHospital($json: get_hospital_input) {
    get_one_hospital: getHospitals(json: $json) {
      id
      name
      mobile
      address
      city
      state
      country
      gst_number
      has_pharmacy
      products {
        id
        name
        hospital_id
        price
      }
      patients {
        id
        name
        mobile
        patient_id
        ref_id
        dob
      }
      users {
        id
        name
        hospital_id
      }
      hospital_files {
        id
        hospital_id
        file_url
        file_size
      }
      error {
        status
        message
      }
    }
  }
`;

export const create_hospital_mutation = gql`
  mutation createHospital($json: create_hospital_input) {
    create_hospital: createHospital(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_hospital_mutation = gql`
  mutation updateHospital($json: update_hospital_input) {
    update_hospital: updateHospital(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_hospital_mutation = gql`
  mutation deleteHospital($json: delete_hospital_input) {
    delete_hospital: deleteHospital(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
