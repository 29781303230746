import { HStack, VStack, Box, ScrollView, Text } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import AdmissionAndVisit from "./admission_visit";
import BookAppointment from "./book_appointment";
import Connections from "./connections";
import PatientDetailsComponent from "./patient_details_component";
import OldVisitDetails from "./old_visit_details";
import App from "./image_preview";
import CurrentVisitDetails from "./current_visit_details";
import VForm from "@views/components/system/ui/antd_form";

import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "@services/redux";
import { get_one_patient_query } from "@services/redux/slices/graphql/graphql_patients";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars-2";
import { debounce } from "lodash";
import { Button, Modal, Form } from "antd";
import { create_hospital_patient_visit } from "@services/redux/slices/graphql/graphql_hospital_patient_visit";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const PatientPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const centeredRef = useRef();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [is_loading, setIsloading] = useState(false);
  const [confirmation_modal, setConfirmationModal] = useState(false);
  const [date_picker_modal_open, setDatePickerModalOpen] = useState(false);
  const [visit_type, setVisitType] = useState();
  const [required_date, setRequiredDate] = useState();
  const [difference, setDifference] = useState(null);
  const getHospitalPatient = useDynamicSelector("getHospitalPatient");
  const { loading: hospital_patient_loading } =
    useDynamicSelector("getHospitalPatient");
  const {
    status: create_hospital_patient_visit_status,
    error: create_hospital_patient_visit_error,
    loading: create_hospital_patient_visit_loading,
  } = useDynamicSelector("createHospitalPatientVisit");

  const createHospitalPatientVisit = (type) => {
    let variables = {
      json: {
        data: {
          hospital_patient_id: id,
          type: type || visit_type,
        },
      },
    };

    let keys = [{ key: "createHospitalPatientVisit", loading: true }];
    dispatch(dynamicRequest(keys, create_hospital_patient_visit, variables));
  };
  const createHospitalPatientVisitWithDateTime = (_date) => {
    let variables = {
      json: {
        data: {
          hospital_patient_id: id,
          type: visit_type,
          datetime: _date,
        },
      },
    };

    let keys = [{ key: "createHospitalPatientVisit", loading: true }];
    dispatch(dynamicRequest(keys, create_hospital_patient_visit, variables));
  };

  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModal(false);
  };
  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };

  const handleDateConfirmationModalClose = () => {
    setDatePickerModalOpen(false);
  };
  const handleDateConfirmationModalOpen = (type) => {
    setVisitType(type);
    setDatePickerModalOpen(true);
  };

  const handleOpen = (type) => {
    setVisitType(type);
    if (
      getHospitalPatient?.my_visits?.[0] &&
      getTimeDifference(getHospitalPatient?.my_visits?.[0]?.datetime)
    ) {
      handleConfirmationModalOpen();
    } else {
      createHospitalPatientVisit(type);
    }
  };
  const handleCreateWithTime = (type) => {
    handleDateConfirmationModalOpen(type);
    // createHospitalPatientVisitWithDateTime(type)
  };

  const dates = [
    { date: "03 March 2020", for_what: "visit", dt: "Follow the Prescription" },
    {
      date: "31 September 2020",
      for_what: "admission",
      range: "31 September 2020 - 3 October 2020",
      dt: "Follow the Prescription 2",
    },
    {
      date: "13 April 2022",
      for_what: "visit",
      dt: "Follow the Prescription 9",
    },
  ];
  const images = [
    "https://wallpaperaccess.com/full/317501.jpg",
    "https://wallpaperaccess.com/full/317501.jpg",
    "https://wallpaperaccess.com/full/317501.jpg",
    "https://wallpaperaccess.com/full/317501.jpg",
    "https://wallpaperaccess.com/full/317501.jpg",
    "https://wallpaperaccess.com/full/317501.jpg",
  ];

  const patientDetails = () => {
    let variables = {
      json: { id: id },
    };

    let keys = [{ key: "getHospitalPatient", loading: true }];
    dispatch(dynamicRequest(keys, get_one_patient_query, variables));
  };
  useEffect(() => {
    patientDetails();
  }, []);

  const handleTitleClick = (i) => {
    const centeredComponent = document.getElementById(`centered-${i}`);
    centeredComponent.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const debouncedHandleTitleClick = debounce(handleTitleClick, 200);

  const getTimeDifference = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diff = date2.getTime() - date1.getTime();

    if (diff <= 6 * 60 * 60 * 1000) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (create_hospital_patient_visit_status === "success") {
      toast.success("Visits Created");
      patientDetails();

      handleCancel();
      handleDateConfirmationModalClose();
      handleConfirmationModalClose();
      dispatch(dynamicClear("createHospitalPatientVisit"));
    } else if (create_hospital_patient_visit_error) {
      toast.error(create_hospital_patient_visit_error?.message);
      handleConfirmationModalClose();
      handleCancel();

      dispatch(dynamicClear("createHospitalPatientVisit"));
    }
  }, [
    create_hospital_patient_visit_status,
    create_hospital_patient_visit_error,
  ]);

  const handleScroll = (e) => {
    const { target } = e;
    const { top, bottom } = target.getBoundingClientRect();
    const containerHeight = bottom - top;

    const visitElements = Array.from(target.children);
    const center = top + containerHeight / 2;

    let closestVisit = null;
    let minDistance = Infinity;

    // Find the visit element that's closest to the center of the viewport
    visitElements.forEach((visit) => {
      const { top, bottom } = visit.getBoundingClientRect();
      const visitHeight = bottom - top;
      const visitCenter = top + visitHeight / 2;
      const distance = Math.abs(visitCenter - center);

      if (distance < minDistance) {
        closestVisit = visit;
        minDistance = distance;
      }
    });

    const index = Number(closestVisit.id.split("-")[1]);
    setCurrentIndex(index);
  };

  useEffect(() => {
    setIsloading(true);
  }, [currentIndex]);

  const handleSubmit = (values) => {
    createHospitalPatientVisitWithDateTime(values?.dob);
  };
  return (
    <HStack height={"100%"} width={"100%"} flex={1}>
      <VStack marginLeft={5} marginTop={5} alignItems={"center"} width={"25%"}>
        <PatientDetailsComponent
          hospital_patient_loading={hospital_patient_loading}
          ref={centeredRef}
          patient_name={getHospitalPatient?.name}
          age={moment(new Date(getHospitalPatient?.dob))}
          gender={getHospitalPatient?.gender}
          id={getHospitalPatient?.ref_id}
          mobile={getHospitalPatient?.mobile}
          address={getHospitalPatient?.address}
          url={getHospitalPatient?.photo_url}
        />

        {/* <VStack mt={"3"} justifyContent={"center"}>
          <Connections
            image_source="https://wallpaperaccess.com/full/317501.jpg"
            relation="Mother"
          />
        </VStack> */}
      </VStack>

      <VStack mx={5} marginTop={5} flex={1}>
        <Scrollbars onScroll={handleScroll}>
          {/* <VisitDetails /> */}
          {getHospitalPatient?.my_visits?.map((date, i) => (
            <div
              key={i}
              id={`centered-${i}`}
              style={{
                minHeight: "280px",
              }}
            >
              <Box marginTop={i === 0 ? 0 : 5}>
                {/* {i === 0 && getTimeDifference(date?.datetime) ? ( */}
                <CurrentVisitDetails
                  hospital_patient_loading={hospital_patient_loading}
                  index={i}
                  images={date?.documents}
                  purpose={date.type}
                  notes={date.notes}
                  date={date.datetime}
                  id={id}
                  visit_id={date?.id}
                  currentIndex={currentIndex}
                  document_pending_requests={date?.document_pending_requests}
                />
                {/* ) : (
                  <OldVisitDetails
                    index={i}
                    images={date?.documents}
                    purpose={date.type}
                    notes={date.notes}
                    date={date.datetime}
                    currentIndex={currentIndex}
                  />
                )} */}
              </Box>
            </div>
          ))}
          <Box height={30} />
        </Scrollbars>
      </VStack>
      <VStack marginRight={5} marginTop={5} width={"18%"}>
        {/* <Box marginBottom={2}>
          <BookAppointment next_appointment="01/04/2023" />
        </Box> */}
        <AdmissionAndVisit
          hospital_patient_loading={hospital_patient_loading}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          handleOpen={handleOpen}
          handleCreateWithTime={handleCreateWithTime}
          handleTitleClick={debouncedHandleTitleClick}
          dates={getHospitalPatient?.my_visits}
        />
      </VStack>
      <Modal
        open={previewOpen}
        title={"Confirmation"}
        onCancel={handleCancel}
        footer={[
          <Button
            key="Ok"
            type="primary"
            loading={false}
            onClick={() => {
              if (
                getHospitalPatient?.my_visits?.[0] &&
                getTimeDifference(getHospitalPatient?.my_visits?.[0]?.datetime)
              ) {
                handleConfirmationModalOpen();
              } else {
                createHospitalPatientVisit();
              }
            }}
          >
            Ok
          </Button>,
          <Button
            key="reject"
            type="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Text>{"hi"}</Text>
      </Modal>
      <Modal
        open={confirmation_modal}
        title={"Confirmation"}
        onCancel={handleConfirmationModalClose}
        footer={[
          <Button
            key="Ok"
            type="primary"
            loading={false}
            onClick={() => {
              createHospitalPatientVisit();
            }}
          >
            Ok
          </Button>,
          <Button
            key="reject"
            type="primary"
            onClick={() => {
              handleConfirmationModalClose();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Text>
          {
            "There is an open visit created already today. Do you still want to create a new visit?"
          }
        </Text>
      </Modal>
      <Modal
        open={date_picker_modal_open}
        title={"Create Visit"}
        onCancel={handleDateConfirmationModalClose}
        footer={null}
      >
        <Form
          form={form}
          name={"Create Visit"}
          layout={"vertical"}
          onFinish={handleSubmit}
          className="user_form ant-select-selector ant-select-selection-search"
        >
          <VForm.Date
            label={t("Date")}
            field={"dob"}
            picker={"date"}
            rules={[
              {
                required: true,
                message: `${t("form:dob_msg")}`,
              },
            ]}
          />

          <HStack variant="submit_hstack">
            <VForm.Button
              isLoading={create_hospital_patient_visit_loading}
              disabled={false}
            >
              {t("Submit")}
            </VForm.Button>
            <Button
              onClick={() => {
                handleDateConfirmationModalClose();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </Modal>
    </HStack>
  );
};

export default PatientPage;
