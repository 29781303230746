import React, { useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "@views/components/system/sidebar/sidebar";
import { Box, HStack, VStack } from "native-base";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { ROUTES } from "@views/routes/my_routes";
import { MdOutlinePeopleAlt, MdOutlineSpaceDashboard } from "react-icons/md";
import { FaHospitalUser, FaRegHospital } from "react-icons/fa";
import { AiOutlineMedicineBox } from "react-icons/ai";
import HeaderBar from "@views/components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/functions";
const { Content } = Layout;

const UserLayout = ({ children }) => {
  const is_logged_in = useCheckLogin();
  const menu_list = [
    // {
    //   key: "dashboard",
    //   icon: <MdOutlineSpaceDashboard />,
    //   label: "Dashboard",
    //   url: ROUTES.DASHBOARD,
    //   accessKey: "Hospital Dashboard",
    // },
    {
      key: "hospitals",
      icon: <FaRegHospital />,
      label: "Hospital",
      url: ROUTES.HOSPITAL,
      accessKey: "Hospital List",
    },
    {
      key: "patients",
      icon: <MdOutlinePeopleAlt />,
      label: "Patients",
      url: ROUTES.PATIENT_LIST,
      accessKey: "Patient List",
    },
    // {
    //   key: "products",
    //   icon: <FaHospitalUser />,
    //   label: "Products",
    //   url: ROUTES.HOSPITAL_PRODUCTS,
    //   accessKey: "Hospital Product List",
    // },
    {
      key: "medicine",
      icon: <AiOutlineMedicineBox />,
      label: "Medicines",
      url: ROUTES.MEDICINES,
      accessKey: "Hospital Medicine List",
    },
  ];
  useEffect(() => {
    if (is_logged_in === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [is_logged_in]);
  return (
    <Layout>
      <HStack flex={1}>
        <Sidebar menu_list={menu_list} />
        <VStack flex={1}>
          <HeaderBar name={"tagore"} />
          <Content>{children}</Content>
        </VStack>
      </HStack>
    </Layout>
  );
};
export default UserLayout;
