import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  Upload,
  Modal,
  Button as _Button,
  Divider,
  Row,
  Col,
} from "antd";
import {
  HStack,
  VStack,
  Box,
  Button,
  Image,
  Text,
  Center,
  Pressable,
  SimpleGrid,
  Spinner,
  Radio,
  Skeleton,
} from "native-base";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { MdBookmarkAdd, MdOutlineDownloadDone } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import { BsCircleFill } from "react-icons/bs";
import { create_hospital_patient_note } from "@services/redux/slices/graphql/graphql_hospital_patient_visit";
import { toast } from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  completeUploadRequest,
  create_file_upload_request,
  delete_visit_card,
  get_one_patient_query,
} from "@services/redux/slices/graphql/graphql_patients";
import { MdNoteAdd } from "react-icons/md";
import ImagePreviewer from "./image_preview";
import colors from "@helpers/colors";
import { BiScan } from "react-icons/bi";
import { QueryRequest } from "@services/apollo/api_service";
import { fileQuery } from "@helpers/constants";
import { getUUID } from "@helpers/functions";

const CurrentVisitDetails = (props) => {
  const [preview_open, setPreviewOpen] = useState(false);
  const [view_remarks, setViewRemarks] = useState(false);
  const [create_remark_open, setCreateRemarkOpen] = useState(false);
  const [file_upload_type, setFileUploadType] = useState("file");

  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [view_remark_content, setViewRemarkContent] = useState("");
  const [create_remark_content, setCreateRemarkContent] = useState("");
  const [notes_text, setNotesText] = useState("");
  const [headers, setHeaders] = useState();
  const [uuid, setUUID] = useState();

  const [current_id, setCurrentID] = useState("");

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const handleCancel = () => setPreviewOpen(false);
  let fileUploadResponse;

  const action =
    fileUploadResponse?.data?.createHospitalPatientVisitDocumentUploadUrl;

  const {
    status: create_hospital_patient_note_status,
    error: create_hospital_patient_note_error,
  } = useDynamicSelector("createHospitalPatientNote");

  const {
    status: create_file_upload_request_status,
    error: create_file_upload_request_error,
    loading: create_file_upload_request_loading,
  } = useDynamicSelector("createFileUploadRequest");

  const {
    status: delete_visit_status,
    error: delete_visit_error,
    loading: delete_visit_loading,
  } = useDynamicSelector("deleteHospitalPatientVisit");

  const {
    status: photoStatus,
    loading: update_photo_loading,
    error: update_photo_error,
  } = useDynamicSelector("completeHospitalPatientVisitDocumentUpload");

  const { loading: signed_url_loading } = useDynamicSelector(
    "createHospitalPatientVisitDocumentUploadUrl"
  );
  const [fileList, setFileList] = useState([
    // "https://wallpaperaccess.com/full/317501.jpg",
    // "https://wallpaperaccess.com/full/317501.jpg",
    // "https://wallpaperaccess.com/full/317501.jpg",
    // "https://wallpaperaccess.com/full/317501.jpg",
  ]);

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const handleViewRemarksCancel = () => {
    setViewRemarks(false);
  };
  const handleViewRemarksOpen = (value) => {
    setViewRemarkContent(value);
    setViewRemarks(true);
  };

  const handleCreateRemarkClose = () => {
    setCreateRemarkOpen(false);
  };

  const handleCreateRemarkOpen = () => {
    setCreateRemarkOpen(true);
  };

  const completeFileUpload = (file) => {
    let file_name = file.url.substring(file.url.lastIndexOf("/") + 1);
    let variables = {
      photo_url: file_name,
      document_type: "case_description",
      hospital_patient_id: props?.id,
      hospital_patient_visit_id: props?.visit_id,
    };

    let keys = [
      { key: "completeHospitalPatientVisitDocumentUpload", loading: true },
    ];
    dispatch(dynamicRequest(keys, completeUploadRequest, variables));
  };

  const patientDetailsWithReload = () => {
    let variables = {
      json: { id: props?.id },
    };

    let keys = [{ key: "getHospitalPatient", loading: true }];
    dispatch(dynamicRequest(keys, get_one_patient_query, variables));
  };
  const patientDetails = () => {
    let variables = {
      json: { id: props?.id },
    };

    let keys = [{ key: "getHospitalPatient", loading: false }];
    dispatch(dynamicRequest(keys, get_one_patient_query, variables));
  };

  const createFileUploadRequest = (remark) => {
    let variables = {
      json: {
        type: "visit_photo",
        remarks: remark,
        hospital_patient_id: props?.id,
        hospital_patient_visit_id: props?.visit_id,
        document_type: "case_description",
      },
    };

    let keys = [{ key: "createFileUploadRequest", loading: true }];
    dispatch(dynamicRequest(keys, create_file_upload_request, variables));
  };

  const deleteVisitCard = () => {
    let variables = {
      json: {
        id: props?.visit_id,
      },
    };

    let keys = [{ key: "deleteHospitalPatientVisit", loading: true }];
    dispatch(dynamicRequest(keys, delete_visit_card, variables));
  };
  const createHospitalPatientNotes = () => {
    let variables = {
      json: {
        data: {
          hospital_patient_id: props?.id,
          hospital_patient_visit_id: props?.visit_id,
          content: notes_text,
        },
      },
    };

    let keys = [{ key: "createHospitalPatientNote", loading: true }];
    dispatch(dynamicRequest(keys, create_hospital_patient_note, variables));
  };
  const uploadButton = (
    <div
      onClick={() => {
        setCurrentID(props?.visit_id);
        setUUID(getUUID());
      }}
      style={{
        marginTop: 2,
        marginBottom: 2,
        width: 25,
        height: 25,
        backgroundColor: "#D1F9D1",
      }}
      // my={2}
      // width={25}
      // height={25}
      // mb={"auto"}
      // backgroundColor="#D1F9D1"
    >
      <Box mt={"5px"}>
        <UploadOutlined color="#fff" />
      </Box>
    </div>
  );

  useEffect(() => {
    if (
      create_hospital_patient_note_status === "success" &&
      current_id === props?.visit_id
    ) {
      toast.success("Notes Created");
      patientDetailsWithReload();
      setNotesText("");
      setCurrentID("");

      dispatch(dynamicClear("createHospitalPatientNote"));
    } else if (
      create_hospital_patient_note_error &&
      current_id === props?.visit_id
    ) {
      toast.error(create_hospital_patient_note_error?.message);
      setCurrentID("");

      dispatch(dynamicClear("createHospitalPatientNote"));
    } else if (
      create_file_upload_request_status === "success" &&
      current_id === props?.visit_id
    ) {
      toast.success("Request Created");
      setCurrentID("");

      patientDetails();
      handleCreateRemarkClose();
      dispatch(dynamicClear("createFileUploadRequest"));
    } else if (
      create_file_upload_request_error &&
      current_id === props?.visit_id
    ) {
      toast.error(create_file_upload_request_error?.message);
      setCurrentID("");

      dispatch(dynamicClear("createFileUploadRequest"));
    } else if (
      delete_visit_status === "success" &&
      current_id === props?.visit_id
    ) {
      toast.success("Visit Deleted");
      setCurrentID("");

      patientDetails();

      dispatch(dynamicClear("createFileUploadRequest"));
    } else if (delete_visit_error && current_id === props?.visit_id) {
      toast.error(delete_visit_error?.message);
      setCurrentID("");

      dispatch(dynamicClear("deleteHospitalPatientVisit"));
    } else if (photoStatus === "success" && current_id === props?.visit_id) {
      toast.success("Document Updated");
      setCurrentID("");

      patientDetails();

      dispatch(dynamicClear("completeHospitalPatientVisitDocumentUpload"));
    } else if (update_photo_error && current_id === props?.visit_id) {
      toast.error(update_photo_error?.message);
      setCurrentID("");

      dispatch(dynamicClear("completeHospitalPatientVisitDocumentUpload"));
    }
  }, [
    create_hospital_patient_note_status,
    create_hospital_patient_note_error,
    create_file_upload_request_status,
    create_file_upload_request_error,
    delete_visit_status,
    delete_visit_error,
    photoStatus,
    update_photo_error,
  ]);

  const UserCustomPhotos = React.memo(function Image({ src }) {
    return <img src={src} width={100} height={100} />;
  });

  const handleUpload = async ({ onSuccess, onError, file }) => {
    setCurrentID(props?.visit_id);
    file.uid = file?.uid?.includes("rc") ? uuid : file.uid;
    const url =
      fileUploadResponse?.data?.createHospitalPatientVisitDocumentUploadUrl
        ?.url;
    fetch(url, {
      method: "PUT",
      headers: { "Content-Type": file.type },
      body: file,
    })
      .then((response) => {
        file.url = response.url.split("?")[0];

        onSuccess(null, file);
        completeFileUpload(file);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleBeforeUpload = async ({ name, type }) => {
    let file_name_uuid = uuid;
    let ext = name.split(".")[1];

    let fileName = `${file_name_uuid}.${ext}`;
    let fileUpload = {
      type: "photo",
      fileName: fileName,
    };

    fileUploadResponse = await QueryRequest(fileQuery, fileUpload);
  };
  const handleFileChanged = ({ file }) => {
    file.uid = file?.uid?.includes("rc") ? uuid : file.uid;
    if (file.status === "removed") {
      let list = [];
      let file_index = fileList.findIndex((x) => x.uid === file.uid);
      fileList.splice(file_index, 1);
    } else if (file.status === "uploading") {
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        uid: uuid,
        url: fileUploadResponse?.data?.createHospitalPatientVisitDocumentUploadUrl?.url.split(
          "?"
        )[0],
      };

      let list = fileList?.map((x) => (x.uid === newFile.uid ? newFile : x));
    }
  };
  return (
    <>
      {props?.hospital_patient_loading ? (
        <Skeleton
          borderColor="coolGray.200"
          endColor="warmGray.50"
          style={{
            width: "100%",
            borderRadius: "15px",
            minHeight: "250px",
          }}
        ></Skeleton>
      ) : (
        <Card
          // id={props.date}
          // bordered={false}
          style={{
            // width: 440,
            backgroundColor: "#94d1cd",
            borderRadius: "15px",
            minHeight: "250px",
          }}
        >
          <VStack>
            <VStack>
              <HStack width="100%">
                {props?.currentIndex === props?.index ? (
                  <Box
                    width={"20px"}
                    height={"20px"}
                    textAlign={"center"}
                    style={{
                      backgroundColor: `#a07aeb`,
                      borderRadius: "100%",
                      alignContent: "center",
                    }}
                  >
                    <Center width={"5px"} m="auto">
                      <BsCircleFill size={"10px"} color={"#fff"} />
                    </Center>
                  </Box>
                ) : (
                  <Box
                    width={"20px"}
                    height={"20px"}
                    textAlign={"center"}
                    style={{
                      backgroundColor: `#a07aeb`,
                      borderRadius: "100%",
                      alignContent: "center",
                    }}
                  ></Box>
                )}
                <Text mx={2} bold>
                  {formatDate(props.date).toUpperCase().replace(",", "")}
                </Text>
              </HStack>
              <Pressable
                position={"absolute"}
                right={5}
                onPress={() => {
                  setCurrentID(props?.visit_id);
                  deleteVisitCard();
                }}
                alignItems={"flex-end"}
              >
                {delete_visit_loading && current_id === props?.visit_id ? (
                  <Spinner size={"sm"} />
                ) : (
                  <AiFillDelete size={"20px"} color={"#111"} />
                )}
              </Pressable>
              <Divider
                style={{
                  color: "#111",
                  borderBlockColor: "#111",
                }}
              />
              {/* <HStack mt={"-1"} space={5} justifyContent="flex-end" width="55%">
            <Box>
              <Button style={{ backgroundColor: "#D1F9D1" }}>
                <Text>Prescription</Text>
              </Button>
            </Box>
            <Box>
              <Button style={{ backgroundColor: "#D1F9D1" }} px={"6"}>
                <Text>Bill</Text>
              </Button>
            </Box>
          </HStack> */}
            </VStack>
            <HStack>
              {/* {images.map((image, index) => {
						console.log(image);
						return (
							<Image
								size={50}
								// borderRadius={100}
								source={{
									uri: `${image}`,
								}}
								alt="Alternate Text"
							/>
						);
					})} */}
              <Box width={"100%"}>
                <Row gutter={[16, 16]} style={{ width: "100%" }}>
                  {props?.images?.length > 0 && (
                    <ImagePreviewer images={props?.images} />
                  )}

                  <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    {props?.document_pending_requests?.length > 0 &&
                      props?.document_pending_requests?.map((x) => {
                        return (
                          <Col
                            span={{
                              base: 6,
                              lg: 4,
                              xl: 4,
                            }}
                          >
                            <Pressable
                              onPress={() => {
                                handleViewRemarksOpen(x.remarks);
                              }}
                              style={{
                                borderRadius: "5px",
                              }}
                              width={60}
                              height={60}
                              alignItems={"center"}
                              justifyContent={"center"}
                              backgroundColor={colors?.green2}
                            >
                              {/* <Text fontSize={12} textAlign={"center"} color={"#fff"}>
                          {x?.remarks}
                        </Text> */}
                              <Text
                                fontSize={12}
                                textAlign={"center"}
                                color={"#fff"}
                              >
                                {"Request Pending"}
                              </Text>
                            </Pressable>
                          </Col>
                        );
                      })}
                    <Box
                      marginLeft={2}
                      borderRadius={"5px"}
                      width={60}
                      height={60}
                      backgroundColor={colors?.green2}
                    >
                      {(create_file_upload_request_loading &&
                        current_id === props?.visit_id) ||
                      (update_photo_loading &&
                        current_id === props?.visit_id) ||
                      (signed_url_loading && current_id === props?.visit_id) ? (
                        <Box
                          borderRadius={"5px"}
                          width={60}
                          height={60}
                          backgroundColor={colors?.green2}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Spinner size={"sm"} />
                        </Box>
                      ) : (
                        <>
                          <Pressable
                            onPress={() => {
                              // handleCreateRemarkOpen();
                              // createFileUploadRequest();
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "5px",
                              }}
                              alignItems={"center"}
                              // justifyContent={"center"}
                              backgroundColor={colors?.green2}
                            >
                              <Upload
                                accept="image/png, image/jpeg"
                                // className="file-upload ant-upload-list-picture-card-container"
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                action={action}
                                headers={headers}
                                fileList={fileList}
                                width={60}
                                customRequest={(e) => handleUpload(e)}
                                beforeUpload={(args) =>
                                  handleBeforeUpload(args)
                                }
                                onChange={(e) => handleFileChanged(e)}
                                // onPreview={handlePreview}
                                // onChange={handleChange}
                                // itemRender={(originNode, file, fileList) => (
                                //   <DragableUploadListItem
                                //     originNode={originNode}
                                //     file={file}
                                //     fileList={fileList}
                                //   />
                                // )}
                              >
                                {fileList.length >= 8 ||
                                props?.has_pending_document_upload_request
                                  ? null
                                  : uploadButton}
                              </Upload>
                              {/* <MdNoteAdd size={30} color={"#fff"} /> */}
                            </Box>
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              handleCreateRemarkOpen();
                              // createFileUploadRequest();
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "5px",
                              }}
                              alignItems={"center"}
                              // justifyContent={"center"}
                              backgroundColor={colors?.green2}
                            >
                              <BiScan size={25} color={"#fff"} />
                            </Box>
                          </Pressable>
                        </>
                      )}
                    </Box>
                  </Row>
                </Row>

                <Modal
                  open={preview_open}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Box>
            </HStack>

            <Divider
              style={{
                color: "#111",
                borderBlockColor: "#111",
              }}
            >
              NOTES
            </Divider>
            {/* <Divider
          style={{
            width: "50px",
          }}
          color={"#111"}
          mt={5}
        /> */}

            <VStack>
              {props?.notes?.map((x) => {
                return (
                  <ul>
                    <li color={"amber.200"}>{x?.content}</li>
                  </ul>
                );
              })}
            </VStack>
            <HStack>
              <Input.TextArea
                value={notes_text}
                onChange={(e) => {
                  setNotesText(e.target.value);
                }}
                onPressEnter={() => {
                  setCurrentID(props?.visit_id);

                  createHospitalPatientNotes();
                }}
                style={{ backgroundColor: "#CCFFCC" }}
                placeholder="Add notes"
                rows={1}
              />
              {/* <VStack> */}
              {/* <Box height={"50%"}></Box> */}
              <Box mx={2} justifySelf={"flex-bottom"}>
                <Button
                  style={{ backgroundColor: "#99FF99" }}
                  onPress={() => {
                    setCurrentID(props?.visit_id);
                    if (notes_text?.length > 0) {
                      createHospitalPatientNotes();
                    }
                  }}
                >
                  <MdOutlineDownloadDone size={"20"} />
                </Button>
              </Box>
              {/* </VStack> */}
            </HStack>
          </VStack>
          <Modal
            open={view_remarks}
            title={"Remarks"}
            footer={[
              <_Button
                key="reject"
                type="primary"
                onClick={() => {
                  handleViewRemarksCancel();
                }}
              >
                Close
              </_Button>,
            ]}
            onCancel={handleViewRemarksCancel}
          >
            <Text>{view_remark_content}</Text>
          </Modal>

          <Modal
            open={create_remark_open}
            title={"Add Remarks"}
            footer={[
              <HStack alignItems={"center"} justifyContent={"flex-end"}>
                <_Button
                  key="approve"
                  type="primary"
                  loading={
                    create_file_upload_request_loading &&
                    current_id === props?.visit_id
                  }
                  onClick={() => {
                    setCurrentID(props?.visit_id);
                    if (create_remark_content?.length > 0) {
                      createFileUploadRequest(create_remark_content);
                    } else {
                      toast.error("Please enter remarks");
                    }
                  }}
                >
                  Create
                </_Button>
                ,
                <_Button
                  key="reject"
                  type="ghost"
                  onClick={() => {
                    handleCreateRemarkClose();
                  }}
                >
                  Cancel
                </_Button>
              </HStack>,
            ]}
            onCancel={handleCreateRemarkClose}
          >
            {/* <Radio.Group
          defaultValue="file"
          name="myRadioGroup2"
          accessibilityLabel="Pick your favorite number"
          value={file_upload_type}
          onChange={(val) => {
            setFileUploadType(val);
          }}
        >
          <Radio colorScheme={"secondary"} value="file" my={1}>
            <Text mx={2}>{"File Upload"}</Text>
          </Radio>

          <Radio colorScheme={"secondary"} value="scan" my={1}>
            <Text mx={2}>{"Scan"}</Text>
          </Radio>
        </Radio.Group> */}
            {/* {file_upload_type === "scan" && ( */}
            <Input.TextArea
              value={create_remark_content}
              onChange={(e) => {
                setCreateRemarkContent(e.target.value);
              }}
              onPressEnter={() => {}}
              style={{ backgroundColor: "#CCFFCC", marginTop: 2 }}
              placeholder="Add Remarks"
              rows={2}
            />
            {/* )} */}
          </Modal>
        </Card>
      )}
    </>
  );
};

export default CurrentVisitDetails;
