import AccessRights from "@views/components/system/access_rights/access_rights";
import { Box } from "native-base";
import React from "react";

const AccessRightsPage = () => {
  return (
    <Box flex={1}>
      <AccessRights />
    </Box>
  );
};
export default AccessRightsPage;
