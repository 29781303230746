import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { retrieveItem } from "@helpers/functions";
//import { logoutRequest } from "../../redux";

// const BASE_URL = "https://dev.vertace.org/docy-dev/api/graphql";
// const BASE_URL = "http://192.168.1.54:5051/graphql";
const BASE_URL = "https://arunjunaicare.in/api/graphql";

// process.env.REACT_APP_BASE_URL;

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const user = retrieveItem("session_id");
  const user_session = retrieveItem("user_session_id");

  return {
    headers: {
      ...headers,

      session_id: user ? user : "",
      user_session_id: user_session ? user_session : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export async function QueryRequest(query, variables, dispatch) {
  try {
    let response = await client.query({
      query,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      return "Not authorized";
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { req_error: e };
  }
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      return "Not authorized";
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { req_error: e };
  }
}
