import React, { useEffect, useState } from "react";
import { Center, Box, Text, VStack, HStack, Button } from "native-base";
import {
  Space,
  Table,
  Input,
  Popconfirm,
  Tooltip,
  Typography,
  Breadcrumb,
  Pagination,
} from "antd";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AntdModal from "../../system/ui/antd_modal/antd_modal";
import HospitalProductDetails from "./hospital_product_details";
import Scrollbars from "@views/components/system/scroll_bars";
import {
  delete_hospital_product_mutation,
  dynamicClear,
  dynamicRequest,
  get_all_hospital_products_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/functions";

const HospitalProductList = () => {
  const { Search } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [actionType, setActionType] = useState("");
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { t } = useTranslation();

  const {
    items: product_items,
    pagination: product_pagination,
    loading,
  } = useDynamicSelector("get_all_hospital_products");

  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_hospital_product");
  const {
    status: updateStatus,
    error: updateError,
    loading: updateLoading,
  } = useDynamicSelector("update_hospital_product");
  const { status: deleteStatus, error: deleteError } = useDynamicSelector(
    "delete_hospital_product"
  );

  useEffect(() => {
    let keys = [{ key: "get_all_hospital_products", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_hospital_products_query, {
        json: {
          page_number: current,
          page_limit: pageLimit,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${t("product_create_success_msg")}`,
      });
      dispatch(dynamicClear("create_hospital_product"));

      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${t("product_update_success_msg")}`,
      });
      dispatch(dynamicClear("update_hospital_product"));

      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${t("product_delete_success_msg")}`,
      });
      dispatch(dynamicClear("delete_hospital_product"));
      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    } else if (actionType === "add" && createStatus === "failure") {
      handleModalClose();
      showNotification({
        type: "failure",
        message: `${t("product_create_failure_msg")}`,
      });
      dispatch(dynamicClear("create_hospital_product"));

      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    } else if (actionType === "edit" && updateStatus === "failure") {
      handleModalClose();

      showNotification({
        type: "failure",
        message: `${t("product_update_failure_msg")}`,
      });
      dispatch(dynamicClear("update_hospital_product"));

      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    } else if (deleteStatus === "failure") {
      showNotification({
        type: "failure",
        message: `${t("product_delete_failure_msg")}`,
      });
      dispatch(dynamicClear("delete_hospital_product"));

      let keys = [{ key: "get_all_hospital_products", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_hospital_products_query, {
          json: {
            page_number: current,
            page_limit: pageLimit,
          },
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const handleDeleteHospitalProduct = (record) => {
    let keys = [{ key: "delete_hospital_product", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_hospital_product_mutation, {
        json: { id: record?.id },
      })
    );
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_hospital_products", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_hospital_products_query, {
        json: {
          page_number: page_number,
          page_limit: page_limit,
        },
      })
    );
    setCurrent(page_number);
    setPageLimit(page_limit);
  };

  const handleSearch = (value) => {};
  const handleEditHospitalProduct = (value) => {
    setActionItem(value);
    setActionType("edit");
    setModal(true);
    // history.push(ROUTES.HOSPITAL_PRODUCTS);
  };

  const handleAdd = () => {
    setModal(true);
    setActionType("add");
    setActionItem({});
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Tax Percentage",
      dataIndex: "tax_percentage",
      key: "tax_percentage",
    },
    // {
    // 	title: "Tags",
    // 	key: "tags",
    // 	dataIndex: "tags",
    // 	render: (_, { tags }) => (
    // 		<>
    // 			{tags.map((tag) => {
    // 				let color = tag.length > 5 ? "geekblue" : "green";
    // 				if (tag === "loser") {
    // 					color = "volcano";
    // 				}
    // 				return (
    // 					<Tag color={color} key={tag}>
    // 						{tag.toUpperCase()}
    // 					</Tag>
    // 				);
    // 			})}
    // 		</>
    // 	),
    // },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link onClick={() => handleEditHospitalProduct(record)}>
              <Space>
                <Tooltip title={t("edit")} placement="bottom">
                  <AiOutlineEdit />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              title={t("error:delete_confirmation")}
              onConfirm={() => handleDeleteHospitalProduct(record)}
              // getPopupContainer={() => document.querySelector('.container-element')}
            >
              <Space>
                <Tooltip title={t("delete")} placement="bottom">
                  <AiOutlineDelete />
                </Tooltip>
              </Space>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Scrollbars style={{ flex: 1 }}>
      <Box variant="text_book_board_heading_box">
        {/* <Text variant="text_book_board_heading_text">
			 {t("hospital_product")}
		  </Text> */}
        <Box marginLeft={"42px"} marginTop={"40px"}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={ROUTES.DASHBOARD}>HOME</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href={ROUTES.HOSPITAL}>HOSPITALS</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>PRODUCTS</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>
      <Box variant="text_book_board_top_box">
        <VStack variant="text_book_board_top_vstack">
          <HStack variant="add_text_book_board_button_hstack">
            {/* <Box key={`header-action`}> */}
            {/* <Button
              type="primary"
              onPress={() => history.push(ROUTES.HOSPITAL)}
            >
              {t("back")}
            </Button> */}
            <Box width={"20%"} marginLeft={"2"}>
              <Search
                className="hospital-search"
                placeholder="Search products"
                onSearch={handleSearch}
                enterButton
                allowClear
              />
            </Box>

            {/* <Button type="primary" onPress={handleSearch}>
				{t("search")}
				</Button> */}
            <Button height={"33px"} type="primary" onPress={handleAdd}>
              {t("add_product")}
            </Button>
            {/* </Box> */}
          </HStack>
          <Box p={5}>
            <Table
              columns={columns}
              dataSource={product_items}
              pagination={false}
            />
            <Pagination
              className="hospital-pagination"
              total={product_pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
              pageSizeOptions={[1, 5, 10, 20, 50]}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} Products`
              }
            />
          </Box>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modal}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("add_product") : t("update_product")}
        component={
          <HospitalProductDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </Scrollbars>
  );
};

export default HospitalProductList;
