import React, { useEffect, useState } from "react";
import { Form, Button, Radio, Space, Input, Select } from "antd";
import { useLocation } from "react-router-dom";
import { Center, Box, Text, HStack } from "native-base";
import HospitalProducts from "@views/pages/hospital_products";
import VForm from "@views/components/system/ui/antd_form";
import { useTranslation } from "react-i18next";
import {
  create_hospital_patient,
  get_all_patients_query,
  get_all_patients_search_query,
  update_hospital_patient,
} from "@services/redux/slices/graphql/graphql_patients";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { showToast } from "@helpers/functions";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import moment from "moment";

const PatientDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const { Search } = Input;

  const dispatch = useDispatch();

  const { items: search_items } = useDynamicSelector(
    "get_hospital_patients_search"
  );

  const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
  const [referred_id, setReferredById] = useState();
  const [search_options, setSearchOptions] = useState([]);
  const [gender, setGender] = useState();
  const [age_number, setAgeNumber] = useState();

  const { loading: createLoading } = useDynamicSelector(
    "createHospitalPatient"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateHospitalPatient"
  );

  const [form] = Form.useForm();
  let temp = [];

  useEffect(() => {
    form.resetFields();
    setIsWhatsAppEnabled(false);
    setGender("");
    setReferredById("");
    setAgeNumber("");

    if (initialValues?.id) {
      form.setFieldsValue({
        ...initialValues,
        dob: moment(new Date(initialValues.dob)),
      });
      let age = moment().diff(initialValues?.dob, "years");
      setAgeNumber(age);
      setIsWhatsAppEnabled(initialValues?.has_whatsapp);
      setGender(initialValues?.gender);
      setReferredById(initialValues?.referred_by_id);
    }
  }, [initialValues]);

  const handleSubmit = (values) => {
    if (initialValues.id) {
      values.has_whatsapp = initialValues?.has_whatsapp || isWhatsAppEnabled;
      values.gender = initialValues?.gender || gender;
      values.referred_by_id = initialValues?.referred_by_id || referred_id;

      let variables = {
        json: { id: initialValues.id, data: values },
      };

      let keys = [{ key: "updateHospitalPatient", loading: true }];
      dispatch(dynamicRequest(keys, update_hospital_patient, variables, "M"));
    } else {
      values.has_whatsapp = isWhatsAppEnabled;
      values.gender = gender;
      values.referred_by_id = referred_id;

      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createHospitalPatient", loading: true }];
      dispatch(dynamicRequest(keys, create_hospital_patient, variables, "M"));
    }
  };

  useEffect(() => {
    if (age_number) {
      const dob = new Date(Date.now() - age_number * 31556952000).toISOString();

      form.setFieldsValue({
        ...initialValues,
        dob: moment(new Date(dob)),
      });
    }
  }, [age_number]);

  const handleWhatsAppEnableChange = (e) => {
    setIsWhatsAppEnabled(e.target.value);
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleChange = (value) => {
    if (value?.length > 2) {
      searchPatient(value);
    }
  };

  const searchPatient = (value) => {
    let variables = {
      json: { search_string: value },
    };

    let keys = [{ key: "get_hospital_patients_search", loading: true }];
    dispatch(dynamicRequest(keys, get_all_patients_search_query, variables));
  };

  useEffect(() => {
    if (search_items) {
      const options = search_items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSearchOptions(options);
    }
  }, [search_items]);

  return (
    <Form
      form={form}
      name={"patient_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={2} spacingY={20} spacingX={10}>
        <VForm.TextBox
          label={t("Name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: `${t("form:name_msg")}`,
            },
          ]}
        />
        <VForm.TextBox
          label={t("ref_id")}
          field={"ref_id"}
          rules={[
            {
              required: true,
              message: `${t("form:ref_id_msg")}`,
            },
          ]}
        />
        <Form.Item label="Gender" field={"gender"}>
          <Radio.Group onChange={handleGenderChange} value={gender}>
            <Space direction="horizontal">
              <Radio value={"m"}>Male</Radio>
              <Radio value={"f"}>Female</Radio>
              <Radio value={"o"}>Others</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <HStack alignItems={"center"} width={"100%"}>
          <Box width={"75%"}>
            <VForm.Date
              label={t("dob")}
              field={"dob"}
              picker={"date"}
              rules={[
                {
                  required: true,
                  message: `${t("form:dob_msg")}`,
                },
              ]}
            />
          </Box>
          <Box mt={2} mx={2} width={"25%"}>
            <Input
              value={age_number}
              onChange={(e) => {
                setAgeNumber(e.target.value);
              }}
              type="number"
            />
          </Box>
        </HStack>

        <VForm.TextBox
          label={t("mobile")}
          field={"mobile"}
          rules={[
            {
              required: true,
              message: `${t("form:mobile_msg")}`,
            },
          ]}
        />
        <Form.Item label="Has WhatsApp?" field={"has_whatsapp"}>
          <Radio.Group
            onChange={handleWhatsAppEnableChange}
            value={isWhatsAppEnabled}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <VForm.TextArea
          label={t("address")}
          field={"address"}
          rules={[
            {
              required: true,
              message: `${t("form:address_msg")}`,
            },
          ]}
        />

        <Form.Item label="Referred By" field={"referred_by_id"}>
          <Select
            value={referred_id}
            showSearch={true}
            style={{
              width: 200,
            }}
            placeholder="Search to Select"
            // optionFilterProp="children"
            onSearch={(value) => {
              handleChange(value);
            }}
            onChange={(e) => {
              setReferredById(e);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            options={search_options}
          />
        </Form.Item>

        {/* <VForm.Select
          label={t("gender")}
          field={"gender"}
          options={genders}
          labelField={"label"}
          valueField={"value"}
          rules={[
            {
              required: true,
              message: `${t("form:name_msg")}`,
            },
          ]}
        /> */}
      </VForm.Grid>

      <HStack variant="submit_hstack">
        <VForm.Button
          isLoading={props.label === "Add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default PatientDetails;
