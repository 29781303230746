import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "antd";
import { HStack, SimpleGrid, View } from "native-base";
import { useDynamicSelector } from "@services/redux";

const ImagePreviewer = (props) => {
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentImageIndex] = useState(false);
  const [array_with_date, setArrayWithDate] = useState([]);

  const getHospitalPatient = useDynamicSelector("getHospitalPatient");
  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const image_urls = getHospitalPatient.my_visits
    .map((visit) => visit.documents.map((doc) => doc))
    .reduce((acc, cur) => acc.concat(cur), [])
    .map((url, index) => ({
      file_name: url?.hospital_file.file_url,
      index,
      id: url?.hospital_patient_visit_id,
      img_id: url?.hospital_file?.id,
    }));

  useEffect(() => {
    let temp_arr1;
    let temp_arr2 = [];
    let temp2;
    let temp1;
    for (let i = 0; i < getHospitalPatient?.my_visits?.length; i++) {
      temp1 = getHospitalPatient?.my_visits[i];

      for (let j = 0; j < image_urls?.length; j++) {
        temp2 = image_urls[j];

        if (temp1?.id === temp2?.id) {
          temp_arr1 = { ...temp2, date: temp1?.datetime };
          temp_arr2.push(temp_arr1);
        }
      }
      setArrayWithDate(temp_arr2);
    }
  }, []);
  const imageSources = props?.images?.flatMap(
    (image) => image?.hospital_file?.file_url || []
  );

  const handleImageClick = (id) => {
    let current_img_index = array_with_date?.find(
      (x) => x.img_id === id
    )?.index;

    setCurrentImageIndex(current_img_index);
    setVisible(true);
  };
  return (
    <>
      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        {props?.images?.map((image, index) => (
          <Col
            key={index}
            span={{
              base: 6,
              lg: 4,
              xl: 4,
            }}
          >
            <Image
              onClick={() => {
                handleImageClick(image?.hospital_file?.id);
                // setVisible(true);
              }}
              key={index}
              alt="img"
              width={60}
              height={60}
              style={{
                objectFit: "cover",
                border: "2px solid #8FBC8F",
              }}
              src={image?.hospital_file?.file_url}
              preview={{ visible: false }}
            />
          </Col>
        ))}
      </Row>
      <div style={{ display: "none", zIndex: 3 }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
            countRender: (current, total) => {
              let rr = array_with_date?.find(
                (x) => x.index === current - 1
              )?.date;
              return formatDate(rr);
            },
            current: currentIndex,
          }}
        >
          {image_urls?.map((x, index) => {
            return (
              <Image
                onClick={() => {}}
                key={index}
                // width={300}
                // height={300}
                style={{
                  objectFit: "cover",
                }}
                src={x.file_name}
                preview={{
                  src: x.file_name,
                }}
              />
            );
          })}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default ImagePreviewer;
