import { gql } from "@apollo/client";

export const get_all_hospital_products_query = gql`
  query getHospitalProducts($json: get_all_hospital_product_input) {
    get_all_hospital_products: getHospitalProducts(json: $json) {
      items {
        id
        name
        hospital_id
        price
        tax_percentage
        is_deleted
        hospital {
          id
          name
          mobile
          address
          city
          state
          country
          gst_number
        }
        invoice_line_items {
          id
          hospital_patient_visit_invoice_id
          hospital_product_id
          quantity
          actual_price
          discounted_price
          hospital_patient_visit_invoice {
            id
            hospital_patient_id
            hospital_patient_visit_id
            total_amount
            tax_amount
          }
          hospital_product {
            id
            name
            hospital_id
            price
            tax_percentage
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_hospital_products_query = gql`
  query getHospitalProduct($json: get_hospital_product_input) {
    get_one_hospital_products: getHospitalProduct(json: $json) {
        id
        name
        hospital_id
        price
        tax_percentage
        is_deleted
        hospital {
          id
          name
          mobile
          address
          city
          state
          country
          gst_number
        }
        invoice_line_items {
          id
          hospital_patient_visit_invoice_id
          hospital_product_id
          quantity
          actual_price
          discounted_price
          hospital_patient_visit_invoice {
            id
            hospital_patient_id
            hospital_patient_visit_id
            total_amount
            tax_amount
          }
          hospital_product {
            id
            name
            hospital_id
            price
            tax_percentage
          }
        }
      error {
        status
        message
      }
    }
  }
`;

export const create_hospital_product_mutation = gql`
  mutation createHospitalProduct($json: create_hospital_product_input) {
    create_hospital_product: createHospitalProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_hospital_product_mutation = gql`
  mutation updateHospitalProduct($json: update_hospital_product_input) {
    update_hospital_product: updateHospitalProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_hospital_product_mutation = gql`
  mutation deleteHospitalProduct($json: delete_hospital_product_input) {
    delete_hospital_product: deleteHospitalProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
